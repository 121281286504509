@import "../../styles/general";

.two-column-text-section {
  padding-top: map_get($dimensions, xl) * 2;
}

.two-column-text-section--padding-bottom {
  padding-bottom: map_get($dimensions, xl) * 2;
}

.section__text {
  ul {
    color: #514C71;
    padding-left: 18px;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    li {
      padding-top: 12px;
      &:first-of-type {
        padding-top: 0;
      }
      span {
        font-weight: 700;
      }
    }
  }
}

.section__img {
  position: relative;
  width: 80%;
  background-image: url(../../assets/img_repetable_bkg.png);
  background-size: contain;

  img {
    width: 108%;
  }
}

.section__text {
  margin-bottom: map_get($dimensions, xxl);

  .button {
    @include primary-button();
    display: inline-block;
  }
}



.section__title {
  @include text-style(
    $color: map_get($colors, background-light-3),
    $font-family: $font-Raleway,
    $font-size: map_get($dimensions, sm),
    $font-weight: 400,
    $line-height: map_get($dimensions, m)
  );
  text-transform: uppercase;
  margin: map_get($dimensions, xs) 0;
}

.section__description-decoration {
  height: 4px;
  width: 150px;
}

.section__description {
  @include text-style(
    $color: map_get($colors, accent-10),
    $font-family: $font-Raleway,
    $font-size: map_get($dimensions, m),
    $font-weight: 600,
    $line-height: map_get($dimensions, l)
  );
  padding-top: map_get($dimensions, base);
  margin: 0 map_get($dimensions, xs) 0 0;
  font-size: 32px;
  line-height: 40px !important;
 
}

.section__info {
  @include text-style(
    $color: map_get($colors, background-light-6),
    $font-family: $font-Raleway,
    $font-size: 1.125rem,
    $font-weight: 400,
    $line-height: map_get($dimensions, l)
  );
}

.decorative__image {
  position: absolute;
  z-index: 1;
}

@media only screen and(min-width: map_get($breakpoints, screen-md)) {
  .two-column-text-section {
    padding-top: map_get($dimensions, xxl) * 2;
  }

  .two-column-text-section--padding-bottom {
    padding-bottom: map_get($dimensions, xxl) * 2;
  }

  .section__title {
    font-size: map_get($dimensions, base);
    line-height: map_get($dimensions, l);
  }
}

@media only screen and(min-width: map_get($breakpoints, screen-lg)) {
  .two-column-text-section {
    padding-top: map_get($dimensions, xxl) * 3;
  }

  .two-column-text-section--padding-bottom {
    padding-bottom: map_get($dimensions, xxl) * 3;
  }

  .section__img {
    width: calc(55% - 4rem);
  }

  .section__text {
    width: calc(40% - 2rem);

  }

  .section__description {
    font-size: map_get($dimensions, xxl);
    line-height: map_get($dimensions, m) * 2;
  }
}
