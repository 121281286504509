@import "styles/general";

.cover-card {
  background-color: map_get($colors, foreground);
  box-shadow: 0 2px 6px 0 transparentize(map_get($colors, background), .84);
  height: 27rem;
  position: relative;
}

.cover-card--overlay {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}

.cover-card-link {
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;
}

.cover-card__cover-container {
  box-sizing: border-box;
  padding-bottom: map_get($dimensions, base);
  width: 100%;
}

.cover-card__cover {
  background: {
    position: center;
    size: cover;
  };
  height: 10.5rem;
  width: 100%;
}

.cover-card__content {
  height: calc(27rem - 10.5rem - 2rem);
  overflow: hidden;
  padding: 0 map_get($dimensions, base);
  position: relative;
}


.cover-card__details {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.details__date,
.cover-card__description {
  @include text-style(
          $color: map_get($colors, accent-4),
          $font-size: map_get($dimensions, base),
          $font-family: $font-Roboto,
          $font-weight: 400,
          $line-height: map_get($dimensions, xl)
  );
  margin-top: 0;
}

.cover-card__title {
  @include text-style(
          $color: map_get($colors, accent-3),
          $font-size: map_get($dimensions, l),
          $font-family: $font-Raleway,
          $font-weight: 600,
          $line-height: map_get($dimensions, base) * 2
  );
  margin: map_get($dimensions, xs) / 2 0;
}

.cover-card__button {
  display: none;
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .cover-card {
    &:hover {
      .cover-card__cover-container {
        display: none;
      }

      .cover-card__content {
        height: auto;
        padding: map_get($dimensions, base);
      }

      .cover-card__title {
        text-decoration: underline;
      }

      .cover-card__button {
        display: block;
        @include primary-button();
        bottom: map_get($dimensions, base);
        left: map_get($dimensions, base);
        position: absolute;
        right: map_get($dimensions, base);
        z-index: 2;
      }

      .cover-card--overlay {
        height: 10rem;
      }
    }
  }
}