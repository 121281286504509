@import "styles/general";

.breadcrumbs {
  display: none;
}

@media only screen and (min-width: map-get($breakpoints, screen-md)) {

  // General breadcrumb styles
  .breadcrumb-general {
    font: {
      family: $font-Raleway;
      size: map_get($dimensions, sm);
    }
  }

  .breadcrumbs {
    align-items: center;
    display: inline-flex;
    margin-top: map_get($dimensions, l) * 2;
  }

  .breadcrumb {
    @extend .breadcrumb-general;
    color: map_get($colors, primary);
    text-decoration: none;
  }

  .breadcrumb-separator {
    display: inline-block;
    color: transparentize(map_get($colors, background), .46);
    padding: 0 map_get($dimensions, xs) / 2;
  }

  .breadcrumb-last {
    @extend .breadcrumb-general;
    color: transparentize(map_get($colors, background), .46);
  }
}
