@import "styles/general";

.pricing-packages-container {
    padding: 16px;
    background-color: #eff3f5;

    h3 {
        font-weight: 500;
        font-size: 28px;
        line-height: 32px;
        color: #333333;
        margin: 0;
    }

    .small-size-description {
        line-height: 24px;
        margin-bottom: 12px;
        margin-top: 8px;
        color: rgba(0, 0, 0, 0.54);
        font-weight: 500;
    }
}

.pricing-cards-container {
    display: flex;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    .plan-name {
        width: 100%;
        margin-bottom: 4px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .thead-card {
        display: flex;
        width: calc(100% - 24px);
        min-width: 148px;
        flex-direction: row;
        div {
            flex: 1;
            align-items: flex-start;
            h3 {
                padding-left: 0;
            }
            span {
                text-align: unset;
                padding-right: 8px;

            }
        }
        .cta-btn {
            flex: 1;
            max-width: 150px;
        }
    }
}

.small-plan-name {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 12px;
    letter-spacing: -0.02em;
    font-family: $font-Roboto;
    font-weight: 400;
    text-transform: capitalize;
    color: #333;
    padding: 5px 0;
    transform: rotate(90deg);
    align-items: flex-start;
    background-color: #fff;
    div {
        width: 22px;
        height: 2px;
        border-radius: 19px;
        margin-top: 4px;
        margin-bottom: 2px;
    }
    &.first {
        div {
            background-color: #59db8d;
        }
    }

    &.second {
        div {
            background-color: #e1b93c;
        }
    }

    &.third {
        div {
            background-color: #48bdff;
        }
    }
}

.thead-card {
    display: none;

    flex-direction: column;
    align-items: center;
    width: 154px;
    background-color: white;
    border-radius: 4px;
    border-top: 5px solid;
    padding: 12px;
    justify-content: space-between;
    div {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &.first {
        border-top-color: #f4c142;
    }
    &.second {
        border-top-color: #eb6642;
    }
    &.third {
        border-top-color: #48bdff;
    }

    h3 {
        margin: 0;
        line-height: map_get($dimensions, l);
        font-weight: 400;
        font-size: map_get($dimensions, m);
        font-family: $font-Roboto;
        margin-bottom: 6px;
    }
    span {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.54);
        font-size: 13px;
        line-height: 15px;
        text-align: center;
        .amount {
            font-weight: 600;
        }
    }

    .cta-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 40px;
        width: 100%;
        margin-top: map_get($dimensions, xs);
        border: none;
        border-radius: 8px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        background-color: white;
        color: #0099ff;
        line-height: 16px;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.02em;
        text-transform: uppercase;
    }
}

.pricing-table {
    * {
        font-family: $font-Raleway;
    }
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;

    width: 100%;
    height: 100%;
    border-spacing: 0;
    background-color: map_get($colors, foreground);
    padding-top: 16px;
    padding-bottom: 80px;
    .packages-word {
        display: none;
    }
    thead {
        tr {
            background-color: map_get($colors, foreground-dark-6);
            height: 65px; //necessary for mobile view
        }

        th {
            position: sticky;
            top: 72px;
            width: 160px;
            max-width: 28px;
            color: #333;
            text-align: left;
            font-weight: 600;
            font-size: map_get($dimensions, m);
            line-height: map_get($dimensions, l);
            vertical-align: top;
            z-index: 1;
            background-color: #fff;
            &.small {
                span,
                a {
                    display: none;
                }
                h3 {
                    margin-bottom: 0;
                }
                .thead-card {
                    height: 20px !important;
                    padding: 12px;
                    align-items: center;
                }
            }

            &:first-of-type {
                width: 100%;
                padding-left: 16px;
                vertical-align: middle;
                font-size: 24px;
                line-height: 28px;
                font-weight: 500;
                div {
                    display: flex;
                    flex-direction: column;
                }
            }

            &:last-of-type {
                padding-right: 16px;
            }
        }
    }

    .features-col {
        .cell-container {
            justify-content: space-between;
            padding-left: 16px;
        }
        span {
            position: relative;
            display: flex;
            margin-left: 4px;
            &:hover .tooltip {
                visibility: visible;
            }
        }
    }

    .cell-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2px;
        min-height: 56px;
        height: 100%;
        line-height: 18px;
        font-size: 13px;
        font-weight: 500;
        border-bottom: 1px solid #9faeb6;
    }

    td:not(.features-col) {
        text-align: center;
        &:last-of-type {
            .cell-container {
                width: 26px;
            }
        }
    }

    .visible {
        display: table-row;
    }

    .hidden {
        display: none;
    }

    .section-header-row {
        cursor: pointer;
    }
    .section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #4397f7;
        font-weight: 600;
        font-size: 17px;
        border: 1px solid #d3dbdf;
        border-radius: 4px;
        margin-top: 8px;
        padding: 8px;
        div {
            display: flex;
            align-items: center;
            text-align: left;
        }
        &__icon {
            margin-right: map_get($dimensions, base);
            width: 32px;
            height: 32px;
        }
        span {
            display: flex;
            width: 32px;
            height: 32px;
        }
    }
}
@media only screen and (min-width: map_get($breakpoints, screen-sm)) {
    //576px
    .pricing-table {
        padding: 0 36px 80px 36px;
        thead {
            th {
                padding: 16px 8px 0 0;
                max-width: unset;
                &:first-of-type {
                    font-size: 32px;
                    line-height: 38px;
                    padding: 16px 0 0;
                }
                &:last-of-type {
                    padding: 16px 0 0 0;
                }
            }
        }
        .section-header {
            font-size: 18px;
            margin-top: 16px;
        }

        .cell-container {
            font-size: 13px;
            line-height: 16px;
        }
        td:not(.features-col):last-of-type {
            .cell-container {
                width: unset;
            }
        }
    }

    .pricing-packages-container {
        padding: 16px 32px;
        h3 {
            font-size: 32px;
            line-height: 38px;
        }
        .pricing-cards-container {
            flex-direction: row;
            .plan-name {
                margin-bottom: 0;
                margin-right: 8px;
                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
        .thead-card {
            width: calc(100% - 24px);
            flex-direction: column;
            div {
                align-items: center;
                min-width: unset;
                h3 {
                    line-height: 24px;
                    font-size: 20px;
                }
                span {
                    text-align: center;
                    padding-right: 0;
                }
            }
            &.first {
                height: 112px;
            }
            &.second {
                height: 122px;
            }
            &.third {
                height: 132px;
            }
            .cta-btn {
                flex: unset;
                max-width: unset;
            }
        }
    }
    .small-plan-name {
        transform: rotate(0deg);
        &.first {
            align-items: flex-start;
        }
        &.second {
            align-items: center;
        }

        &.third {
            align-items: flex-end;
        }
    }
}

//package cards are displayed next to eachother on a row
@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  

    .pricing-table {
        .cell-container {
            font-size: 14px;
            line-height: 18px;
        }
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
    //992px
    .pricing-table {
        padding: 0 0 120px 0;
        thead th {
            width: 153px;
            background-color: #eff3f5;
            padding: 0;
            padding-top: 24px;
            vertical-align: bottom;
            &:first-of-type {
                padding-left: 42px;
            }
            &:last-of-type {
                padding-right: 42px;
                padding-top: 24px;
            }
        }

        .section-header-row {
            td {
                padding: 0 42px;
            }
        }

        .features-col {
            padding-left: 42px;
            .cell-container {
                padding-left: 12px;
            }
        }
        td:not(.features-col) {
            &:last-of-type {
                padding-right: 42px;
            }
        }

        .packages-word {
            display: inline;
            &:first-of-type {
                margin-bottom: 8px;
            }
            &:last-of-type {
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.54);
                white-space: normal;
                max-width: 540px;
            }
        }

        .thead-card {
            display: flex;
            margin-right: 12px;
            padding: 22px 12px 12px;
            &.first {
                height: 111px;
            }
            &.second {
                height: 121px;
            }
            &.third {
                margin-right: 0;
                height: 131px;
            }
            &.ad-supported {
                min-width: 236px;
            }
        }
    }

    .pricing-packages-container {
        display: none;
    }

    .small-plan-name {
        display: none;
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
    .pricing-table {
        padding: 50px 44px;
    }
}
