@import "styles/general";

.app-container {
  margin-top: map_get($dimensions, l) * 3;
  flex-grow: 1;
}

.app-container--has-banner {
  margin-top: map_get($dimensions, l) * 8;
  flex-grow: 1;
}