@import "styles/general";

.position {
  align-items: center;
  background-color: map_get($colors, foreground);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  height: 160px;
  text-decoration: none;
  width: 100%;
}


.position__title {
  @include text-style(
          $color: map_get($colors, accent-3),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, m),
          $font-weight: 600,
          $line-height: map_get($dimensions, l)
  );
  padding: map_get($dimensions, base)  map_get($dimensions, base) 0;
  margin: 0;
  text-align: center;
}

.position__description {
  @include text-style(
          $color: transparentize(map_get($colors, background), .46),
          $font-family: $font-Roboto,
          $font-size: 14px,
          $font-weight: 400,
          $line-height: map_get($dimensions, base)
  );
  margin-bottom: 0;
  text-align: center;
  text-transform: uppercase;
}

.position__redirect {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: map_get($dimensions, base);
  text-decoration: none;
  width: 100%;

  span {
    @include text-button();
    padding: 0;
  }

  i {
    color: map_get($colors, accent-1);
  }
}


@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .position__redirect {
    display: none;
  }

  .position {
    &:hover {
      background-color: map_get($colors,foreground-dark-4);

      .position__redirect {
        display: flex;
      }
    }
  }
}