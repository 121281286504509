@import "styles/general";

.leadership-card {
  align-items: center;
  background-color: map_get($colors, foreground);
  display: flex;
  flex-direction: column;
  height: 23rem;
  position: relative;
  width: 100%;
}

.leadership-card__avatar-container {
  align-items: center;
  background: url("../../../../../assets/leadership/leadership-avatar-decorator.svg");
  box-sizing: border-box;
  display: flex;
  height: 181px;
  padding: map_get($dimensions, xs);
  margin: map_get($dimensions, l) 0;
  width: 181px;
}

.leadership-card__avatar-link {
  height: 100%;
  width: 100%;
}

.leadership-card__avatar {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.leadership-card__name {
  @include text-style(
          $color: map_get($colors, accent-10),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, l),
          $font-weight: 600,
          $line-height: map_get($dimensions, m)
  );
  margin: 0;
  text-align: center;
}

.leadership-card__name-decorator {
  background-color: map_get($colors, primary);
  display: block;
  margin: map_get($dimensions, xs) auto;
  height: 1px;
  width: 65px;
}

.leadership-card__position {
  @include text-style(
          $color: transparentize(map_get($colors, background), .46),
          $font-family: $font-Roboto,
          $font-size: map_get($dimensions, base),
          $font-weight: 400,
          $line-height: map_get($dimensions, base)
  );
  text-align: center;
}

.leadership-card__footer {
  align-items: center;
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 map_get($dimensions, m) map_get($dimensions, l);
  position: absolute;
  width: 100%;
}


.leadership-card__social-link {
  padding: map_get($dimensions, m) / 2;
}

.leadership-card__more-info {
  @include text-style(
          $color: map_get($colors, primary),
          $font-family: $font-Roboto,
          $font-size: map_get($dimensions, s),
          $font-weight: 400,
          $line-height: map_get($dimensions, base)
  );
  align-items: center;
  display: flex;
  text-decoration: none;

  i {
    color: map_get($colors, accent-1);
    font-size: map_get($dimensions, m);
    margin-left: 5px;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .leadership-card__name-decorator {
    display: none;
  }
  .leadership-card__footer {
    display: none;
  }

  .leadership-card {
    justify-content: center;

    &:hover {
      background-color: map_get($colors, foreground-dark-4);
      justify-content: flex-start;

      .leadership-card__name-decorator {
        display: block;
      }

      .leadership-card__footer {
        display: flex;
      }
    }
  }
}