@import "styles/general";

.article-actions {
  display: flex;
  justify-content: space-between;
  margin: map_get($dimensions, xl) * 2 0;
}

.article-actions-link {
  @include text-style(
          $color: map_get($colors, primary),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, base),
          $font-weight: 600,
          $line-height: map_get($dimensions, m)
  );
  align-items: center;
  display: flex;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  i {
    color: map_get($colors, background);
    margin-right: map_get($dimensions, base) / 2;
  }
}
