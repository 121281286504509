@import "styles/general";

.cookies-consent-container {
  background-color: map_get($colors, primary) !important;
  color: map_get($colors, background) !important;
  font: {
    family: $font-Raleway;
    size: map_get($dimensions, base);
    weight: 500;
  }
}

.cookies-consent-link {
  color: map_get($colors, foreground);
  margin-left: map_get($dimensions, xs) / 2;
  text-decoration: none;
}

.cookies-consent-buttons {
  background-color: map_get($colors, foreground) !important;
  border-radius: map_get($dimensions, m) !important;
  color: map_get($colors, background) !important;
  font: {
    family: $font-Raleway;
    size: map_get($dimensions, sm);
    weight: 600;
  }
  padding: map_get($dimensions, xs) map_get($dimensions, m) !important;
}