@import "styles/general";

.thank-you-container {
  background-color: map_get($colors, foreground-dark-6);
}

.thank-you-decoration {
  display: block;
  margin: 0 auto;
  padding: map_get($dimensions, xxl) 0;
}

.thank-you__title {
  @include text-style(
          $color: map_get($colors, accent-3),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, l),
          $font-weight: 700,
          $line-height: map_get($dimensions, base) * 2
  );
  margin: 0;
  text-align: center;
}

.thank-you__description {
  @include text-style(
          $color: map_get($colors, background),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, base),
          $font-weight: 400,
          $line-height: map_get($dimensions, l)
  );
  margin: 0;
  text-align: center;
  padding: map_get($dimensions, m) 0 map_get($dimensions, xxl);
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .thank-you-decoration {
    padding: map_get($dimensions, xxl) * 2 0;
  }

  .thank-you__title {
    font-size: map_get($dimensions, m) * 2;
  }

  .thank-you__description {
    font-size: map_get($dimensions, m);
    padding-bottom: map_get($dimensions, xxl) * 2;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .thank-you-decoration {
    padding-top: map_get($dimensions, m) * 7;
  }
  .thank-you__description {
    padding-bottom: map_get($dimensions, m) * 10;
  }
}