@import "styles/general";

.header__content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: map_get($dimensions, base) * 2 0;
}

.header__title {
  @include text-style(
          $color: transparentize(map_get($colors, foreground), .13),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, base) * 2,
          $font-weight: 700,
          $line-height: map_get($dimensions, m) * 2
  );
  margin: 0;
}

.header__title-decoration {
  display: none;
}

.header__description {
  @include text-style(
          $color: map_get($colors, foreground-dark-8),
          $font-family: $font-Roboto,
          $font-size: map_get($dimensions, m),
          $font-weight: 300,
          $line-height: map_get($dimensions, base) * 2
  );
  margin: map_get($dimensions, base) * 2 0 map_get($dimensions, m) * 2;
}

.header__button {
  @include primary-button();
  cursor: pointer;
  display: inline-block;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .header__content {
    width: 70%;
    padding: map_get($dimensions, base) * 4 0;
  }

  .header__title {
    font-size: map_get($dimensions, l) * 2;
    line-height: map_get($dimensions, xl) * 2;
  }

  .header__description {
    font-size: map_get($dimensions, l);
    line-height: map_get($dimensions, base) * 2;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .header__content {
    width: 50%;
    padding: map_get($dimensions, m) * 4 0;
  }
}