@import "styles/general";

.pricing-header {
    * {
        font-family: $font-Raleway;
    }
    background-color: map_get($colors, foreground);
    .title,
    .buttons-toggle,
    .buttons-toggle div,
    .pricing-header__bottom {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    h1,
    h2, span {
      
        text-align: center;
    }
    span {
        line-height: map_get($dimensions, l);
        font-weight: 500;
    }
    &__top {
        background-image: url(../../../../assets/automated-content/automatedcontent_header_background.png);
        background-repeat: no-repeat;
        padding: 80px 16px;
        background-position: center;
        background-size: cover;
    
        .title {
            flex-direction: column;
            color: map_get($colors, foreground);
            margin-bottom: 40px;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;
            h1 {
                margin-bottom: 24px;
                margin-top: 0;
                line-height: map_get($dimensions, xxl);
                font-weight: 700;
                font-size: map_get($dimensions, l);
            }
        }

        .buttons-toggle {
            padding: 8px;
            margin: 0 auto;
            max-width: 324px;
            background: map_get($colors, foreground);
            box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.01);
            border-radius: 8px;
            border: 1px solid map_get($colors, foreground-dark-6);
            div {
                cursor: pointer;
                height: 40px;
                max-width: 156px;
                width: 100%;
                line-height: map_get($dimensions, m);
                font-weight: 600;
                font-size: map_get($dimensions, sm);
                color: map_get($colors, primary);
           
                &.active {
                    background-color: #4397f7;
                    color: map_get($colors, foreground);
                    font-weight: 700;
                    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
                    border-radius: 8px;
                }
            }
        }
    }

  
}

@media only screen and (min-width: map_get($breakpoints, screen-sm)) {
    .pricing-header {
        .title {
            margin-bottom: 30px;
            h1 {
                margin-bottom: 8px;
                line-height: 38px;
                font-size: map_get($dimensions, xxl);
            }
        }

        .buttons-toggle {
            max-width: 366px;
            div {
                max-width: 192px;
                height: 56px;
                font-size: map_get($dimensions, base);
            }
        }

      
    }
}

