@import "styles/general";

.contact-form-section {
  background-color: map_get($colors, foreground-dark-6);
  padding: map_get($dimensions, m) * 2 0;
}

.contact-form-section__title {
  @include text-style(
          $color: map_get($colors, accent-10),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, m),
          $font-weight: 600,
          $line-height: map_get($dimensions, l)
  );
  margin: 0;
  width: 85%;
}

.contact-form-section__description {
  @include text-style(
          $color: map_get($colors, background-light-6),
          $font-family: $font-Raleway,
          $font-size: 1.125rem,
          $font-weight: 500,
          $line-height: map_get($dimensions, l)
  );
  margin: map_get($dimensions, base) 0;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .contact-form-section {
    padding: map_get($dimensions, m) * 4 0;
  }

  .contact-form-section-content {
    margin: 0 auto;
    max-width: 850px;
  }

  .contact-form-section__title {
    font-size: map_get($dimensions, xxl);
    line-height: map_get($dimensions, m) * 2;
    width: 60%;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .contact-form-section {
    padding: map_get($dimensions, m) * 6 0;
  }
}