@import "styles/general";

.careers-carousel-container {
  position: relative;

  .carousel__top-decoration,
  .carousel__bottom-decoration {
    display: none;
  }

  .alice-carousel__stage-item {
    img {
      display: block;
      width: 100%;
    }
  }

  .alice-carousel__dots {
    margin-top: map_get($dimensions, xxl);
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .careers-carousel-container {

    .carousel__top-decoration,
    .carousel__bottom-decoration {
      display: block;
      position: absolute;
    }

    .carousel__top-decoration {
      top: -130px;
      right: -75px;
      z-index: -1;
    }

    .carousel__bottom-decoration {
      bottom: 25px;
      left: -50px;
      z-index: -1;
    }

    .alice-carousel__stage-item {
      img {
        margin: 0 auto;
        width: 96%;
      }
    }
  }
}
