@import "styles/general";

.contact-form--contact-page {
  margin: 0 auto;
  max-width: 500px !important;
}

.contact-form-container {
  margin: map_get($dimensions, xl) * 2 auto 0;
  max-width: 850px;
  position: relative;

  .contact-form-decoration {
    max-width: 120px;
    position: absolute;
    right: -20px;
    top: -30px;
    z-index: 1;
  }

  .contact-form {
    background-color: map_get($colors, foreground);
    border-radius: 4px;
    box-shadow: 0 2px 16px 0 map_get($colors, primary-light-4);
    box-sizing: border-box;
    padding: map_get($dimensions, base) * 2 map_get($dimensions, base);
    position: relative;
    z-index: 2;

    .hubspot-form {
      background-color: map_get($colors, foreground);
      font-family: $font-Raleway !important;
      width: 100% !important;

      .hs_error_rollup {
        display: none;
      }

      fieldset {
        width: 100%;

        .field {
          display: flex;
          flex-direction: column;
          margin-bottom: 2rem;
          width: 100%;

          >label {
            color: transparentize(map_get($colors, background), .46) !important;
            font-size: 1rem;
          }

          input, select, textarea {
            border-bottom: 1px solid transparentize(map_get($colors, background), .62);
            border-left: none;
            border-right: none;
            border-top: none;
            box-shadow: none;
            box-sizing: border-box;
            font-size: map_get($dimensions, base) !important;
            height: auto !important;
            min-height: 2.5rem !important;
            padding: 0.2em 0.3em !important;
            width: 100% !important;

            &:focus {
              border-bottom: 1px solid map_get($colors, primary);
              outline: none;
            }
          }

          input, textarea {
            padding-bottom: map_get($dimensions, xs) !important;
          }

          select {
            background: #ffffff;
            line-height: 3rem;
          }

          .hs-error-msgs {
            bottom: 0 !important;
            left: 0 !important;
            list-style-type: none;
            margin-bottom: 0;
            margin-top: 0;
            max-width: 100%;
            padding-left: 0;
            position: relative;
            right: auto !important;
            top: 5px !important;

            .hs-error-msg {
              background-color: transparent;
              background-image: none;
              border: 0;
              border-radius: 0;
              box-shadow: none;
              color: #e51b00;
              margin: 0;
              max-width: 100%;
              padding: 0 0 0 4px;
              text-shadow: none;
            }
          }
        }
      }

      .hs-submit {
        margin-top: 40px;
      }

      .hs-button {
        background: map_get($colors, primary) !important;
        border: none !important;
        border-radius: map_get($dimensions, m);
        box-shadow: none !important;
        color: rgba(255, 255, 255, 0.87) !important;
        cursor: pointer;
        padding: map_get($dimensions, xs) map_get($dimensions, m) !important;
        text-transform: uppercase;
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .contact-form-container {
    margin-top: map_get($dimensions, xxl) * 2;

    .contact-form {
      padding: map_get($dimensions, m) * 3 map_get($dimensions, m) * 4;
    }

    .contact-form-decoration {
      max-width: 185px;
      right: -20px;
      top: -55px;
    }
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .contact-form-container {
    .contact-form {
      padding: map_get($dimensions, m) * 4 map_get($dimensions, m) * 5;
    }

    .contact-form-decoration {
      right: -35px;
    }
  }

  .contact-form--contact-page {
    .contact-form {
      padding: map_get($dimensions, m) * 3 map_get($dimensions, m) * 4;
    }
  }

}
