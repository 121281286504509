@import "styles/general";

.main-article {
  box-shadow: 0 2px 6px 0 transparentize(map_get($colors, background), .84);
  height: 27rem;
  margin: map_get($dimensions, base) 0;
  position: relative;
}

.main-article--overlay {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}

.main-article-md {
  display: none;
}

.main-article-mobile {
  display: block;
  height: 100%;
  text-decoration: none;
  width: 100%;

  .main-article__cover-container {
    box-sizing: border-box;
    padding: map_get($dimensions, base);
    width: 100%;
  }

  .main-article__content {
    height: calc(27rem - 10.5rem - 2rem);
    overflow: hidden;
    padding: 0 map_get($dimensions, base);
    position: relative;
  }
}

.main-article__cover {
  background: {
    position: center;
    size: cover;
  };
  height: 10.5rem;
}

.main-article__additional-info {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .additional-info__logo {
    max-height: 50px;
    max-width: 100px;
  }
}

.main-article__title {
  @include text-style(
          $color: map_get($colors, accent-3),
          $font-size: map_get($dimensions, l),
          $font-family: $font-Raleway,
          $font-weight: 600,
          $line-height: map_get($dimensions, base) * 2
  );
}

.main-article__date,
.main-article__description {
  @include text-style(
          $color: map_get($colors, accent-4),
          $font-size: map_get($dimensions, base),
          $font-family: $font-Roboto,
          $font-weight: 400,
          $line-height: map_get($dimensions, l)
  )
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .main-article {
    box-shadow: none;
    height: auto;
    margin: map_get($dimensions, base) * 3 0;
  }

  .main-article-mobile {
    display: none;
  }

  .main-article--overlay {
    display: none;
  }

  .main-article-md {
    display: block;

    .additional-info__date-decoration,
    .cover__left-decoration,
    .cover__bottom-decoration,
    .cover__right-decoration {
      display: none;
    }

    .main-article__cover-container {
      display: block;
      height: 20rem;
      width: 100%;
    }

    .main-article__cover {
      height: 100%;
      width: 100%;
    }

    .main-article__content {
      margin-top: map_get($dimensions, l);
    }

    .main-article__additional-info {

      .additional-info__logo {
        max-height: 75px;
        max-width: 150px;
      }
    }

    .main-article__button {
      @include outline-button();
      color: map_get($colors, primary);
      display: inline-block;
      z-index: 1;
    }
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .main-article {
    margin: map_get($dimensions, m) * 4 0;
  }

  .main-article-md {
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;

    .main-article__content,
    .main-article__cover-container {
      width: 50%;
    }

    .main-article__content {
      box-sizing: border-box;
      margin: 0;
      padding-right: map_get($dimensions, base);
    }

    .main-article__cover-container {
      box-sizing: border-box;
      position: relative;
    }

    .cover__left-decoration {
      display: block;
      position: absolute;
      margin: -44px 0 0 -12px;
    }

    .cover__bottom-decoration {
      display: block;
      position: absolute;
      margin: -50px 0 0 100px;
    }

    .cover__right-decoration {
      display: block;
      position: absolute;
      right: -10px;
      margin-top: -126px;
    }

    .main-article__title {
      font-size: map_get($dimensions, xxl);
      line-height: map_get($dimensions, m) * 2;
    }

    .main-article__additional-info {
      align-items: flex-start;
    }

    .additional-info__date-decoration {
      display: block;
      margin-top: map_get($dimensions, base) / 2;
      margin-bottom: map-get($dimensions, m);
    }
  }
}