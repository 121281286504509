@import "styles/general";

.form-section {
  padding-bottom: map_get($dimensions, xxl);
  min-height: 500px;
}

.form-section__decoration {
  bottom: 0;
  position: absolute;
  left: 0;
  max-width: 100%;
}

.form-section-info__title {
  @include text-style(
          $color: map_get($colors, 10),
          $font-size: map_get($dimensions, xxl),
          $font-family: $font-Raleway,
          $font-weight: 700,
          $line-height: map_get($dimensions, base) * 2
  );
}

.form-section-info__title-decorator {
  background-color: map_get($colors, accent-2);
  height: 4px;
  width: 115px;
}

.form-section-info__description {
  @include text-style(
          $color: map_get($colors, background),
          $font-size: map_get($dimensions, base),
          $font-family: $font-Raleway,
          $font-weight: 400,
          $line-height: map_get($dimensions, xxl)
  );
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .form-section {
    padding: map_get($dimensions, m) * 2 0;
  }

  .form-section-info {
    width: 60%;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .form-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: map_get($dimensions, m) * 3;
  }

  .form-section-info {
    width: 45%;
  }

  .contact-form-container {
    width: 50%;
  }
}