@import "styles/general";

.article {
  margin: 0 auto;
  max-width: 677px;
}

.article-info {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: map_get($dimensions, xl) 0 map_get($dimensions, base) / 2;

  .article-info__logo-container {
    max: {
      height: 4.5rem;
      width: 7rem;
    }
  }

  .article-info__logo {
    max: {
      height: 100%;
      width: 100%;
    }
  }

  .article-info__date {
    @include text-style(
            $color: transparentize(map_get($colors, background), .13),
            $font-family: $font-Roboto,
            $font-size: map_get($dimensions, base),
            $font-weight: 400,
            $line-height: map_get($dimensions, l)
    );
  }
}

.article__title {
  @include text-style(
          $color: map_get($colors, background),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, l),
          $font-weight: 600,
          $line-height: map_get($dimensions, base) * 2
  );
  margin-bottom: map-get($dimensions, m);
}


.article__description-container {
  background-color: map_get($colors, primary-light-4);
  padding:  map_get($dimensions, m);
  margin-bottom: map_get($dimensions, l);
}

.article__description-container__logo-container {
  max: {
    height: 4.5rem;
    width: 7rem;
  }
}

.article__description-container__logo {
  max: {
    height: 100%;
    width: 100%;
  }
}


.article-actions {
  display: flex;
  justify-content: space-between;
  margin: map-get($dimensions, l) * 2 0;
}

.article__description {
  @include text-style(
          $color: transparentize(map_get($colors, background), .13),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, base),
          $font-weight: 400,
          $line-height: map_get($dimensions, l)
  );
  margin-top: map-get($dimensions, m);
}

.article-actions__link {
  @include text-style(
          $color: map_get($colors, primary),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, base),
          $font-weight: 600,
          $line-height: map_get($dimensions, m)
  );
  align-items: center;
  display: flex;
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  i {
    color: map_get($colors, background);
    margin-right: map_get($dimensions, base) / 2;
  }
}

@media only screen and (min-width: map-get($breakpoints, screen-md)) {
  .article-info {
    .article-info__logo-container {
      max: {
        height: 6rem;
        width: 9rem;
      }
    }
  }
  .article__title {
    font-size: map_get($dimensions, xl);
    line-height: map_get($dimensions, m) * 2;
    margin-bottom: map_get($dimensions, base) * 2;
    margin-top: map_get($dimensions, base);
  }

  .article__description-container {
    padding:  map_get($dimensions, base) * 2;
    margin-bottom: map_get($dimensions, l) * 2;
  }

  .article__description {
    margin-top: map_get($dimensions, xl);
  }
}
