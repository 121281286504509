@import "styles/general";

.office__city {
  @include text-style(
          $color: map_get($colors, accent-3),
          $font-size: map_get($dimensions, xl),
          $font-family: $font-Raleway,
          $font-weight: 700,
          $line-height: map_get($dimensions, m) * 2
  );
  margin: 0;
}

.office__location {
  width: 100%;
}

.office__address {
  @include text-style(
          $color: map_get($colors, background),
          $font-size: map_get($dimensions, base),
          $font-family: $font-Raleway,
          $font-weight: 400,
          $line-height: map_get($dimensions, xl)
  );
}

