@import "styles/general";

.top-banner {
  align-items: center;
  background-image: url(../../assets/banner/dark-bg-narrow.webp), linear-gradient(rgba(86, 2, 107, 1), rgba(136, 93, 147, 1));
  background-position: center center;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 7.5rem;
  justify-content: center;
  margin: 0;
  padding: 0 1.5rem;
  text-decoration: none;
  word-break: break-word;

  @media only screen and (max-width: map_get($breakpoints, screen-sm)) {
    height: 13rem;
  }
}

.top-banner__wrapper {
  max-width: 64rem;
}

.top-banner__info-section {
  align-items: center;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: map_get($breakpoints, screen-sm)) {
    flex-direction: column;
  }
}

.top-banner__image {
  padding: 10px 20px 10px 0;
  width: 33%;

  @media only screen and (max-width: map_get($breakpoints, screen-sm)) {
    width: 90%;
  }

  img {
    height: auto;
    max-width: 100%;
  }
}

.top-banner__title {
  color: #ffffff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 1.15;
  margin-left: 2%;
  width: 33%;
  word-break: break-word;

  @media only screen and (max-width: map_get($breakpoints, screen-sm)) {
    width: 90%;
  }
}

.top-banner__action-btn {
  margin-left: 2%;
  padding: 10px;
  text-align: center;
  width: 33%;

  @media only screen and (max-width: map_get($breakpoints, screen-sm)) {
    width: 90%;
  }

  a {
    border: 2px solid #ffffff;
    border-radius: 100px;
    color: #FFFFFF;
    display: inline-block;
    font-family: arial,helvetica,sans-serif;
    font-size: 15px;
    font-style: normal;
    font-weight: normal;
    line-height: 1.15;
    padding: 12px 18px;
    text-align: center;
    text-decoration: none;
    word-break: break-word;

    &:hover {
      background-color: #FFFFFF;
      color: #000000;
    }
  }
}

.top-banner__close-btn {
  cursor: pointer;
  fill: #FFFFFF;
  height: 16px;
  padding: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 16px;
  z-index: 1;
}
