@import "styles/general";

.leadership {
  padding-top: map_get($dimensions, base);
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .leadership {
    padding-top: map_get($dimensions, base) * 3;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .leadership {
    padding-top: map_get($dimensions, m) * 4;
  }
}