$colors: (
        primary: #0099FF,
        primary-light-1: #38A8FF,
        primary-light-2: #BBDFFF,
        primary-light-3: #D1E9FF,
        primary-light-4: #E3F3FE,
        primary-light-5: #C4D8E8,
        primary-light-6: #048BF1,
        primary-light-7: #0467CB,
        background: #000000,
        background-light-1: #111111,
        background-light-2: #212121,
        background-light-3: #757575,
        background-light-4: #151515,
        background-light-5: #0D192D,
        background-light-6: #514C71,
        foreground: #FFFFFF,
        foreground-dark-1: #F5F5F5,
        foreground-dark-2: #EBEBEB,
        foreground-dark-3: #F9F9F9,
        foreground-dark-4: #F6F9FD,
        foreground-dark-5: #D8D8D8,
        foreground-dark-6: #EFF3F5,
        foreground-dark-7: #AEAEAE,
        foreground-dark-8: #BBBBBB,
        foreground-dark-9: #B2B2B2,
        accent-1: #86E097,
        accent-2: #F2DC4F,
        accent-3: #0248AC,
        accent-4: #909EB8,
        accent-5: #E9B800,
        accent-6: #FD575C,
        accent-7: #00BC73,
        accent-8: #FFCC00,
        accent-9: #E1B93C,
        accent-10: #0D192D,
        accent-11: #E87140,
        accent-12: #00853E,
        accent-13: #474747,
        accent-14: #727272,
);