@import "styles/general";

.not-found-container {
  background-color: map_get($colors, foreground-dark-6);
  display: flex;
  align-items: center;
  min-height: 500px;
}

.not-found__title {
  @include text-style(
          $color: map_get($colors, accent-3),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, l),
          $font-weight: 700,
          $line-height: map_get($dimensions, base) * 2
  );
  margin: 0;
  text-align: center;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .not-found__title {
    font-size: map_get($dimensions, m) * 2;
  }
}