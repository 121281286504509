@import "styles/general";

.press-list {
  margin-bottom: map_get($dimensions, base);
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .press-list {
    margin-bottom: map_get($dimensions, base) * 3;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .press-list {
    margin-bottom: map_get($dimensions, m) * 4;
  }
}