@import "styles/general";

.offices-list {
  background-color: map_get($colors, foreground-dark-6);
  padding: map_get($dimensions, m) * 2 0;
}

.offices-list__title {
  @include text-style(
          $color: map_get($colors, background),
          $font-size: map_get($dimensions, xxl),
          $font-family: $font-Raleway,
          $font-weight: 400,
          $line-height: map_get($dimensions, xxl)
  );
  font-variant-ligatures: no-common-ligatures;
}