@import "styles/general";

.homepage-logos-section {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 75rem;

  padding: map_get($dimensions, l) * 2 map_get($dimensions, l);
  position: relative;

  &.pricing-logo-carousel {
    background-color: #eff3f5;
  }

  .homepage-logos__title {
    @include text-style(
      $color: transparentize(map_get($colors, background), 0.13),
      $font-size: map_get($dimensions, l),
      $font-family: $font-Raleway,
      $font-weight: 500,
      $line-height: map_get($dimensions, xl)
    );
    text-align: center;
    margin: 0;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
  }

  .alice-carousel__stage {
    mix-blend-mode: multiply;
  }

  .alice-carousel__stage-item {
    img {
      display: block;
      width: 100%;
    }
  }

  .alice-carousel__dots {
    margin-top: 0;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .homepage-logos-section {
    padding: map_get($dimensions, xxl) * 2 map_get($dimensions, l);
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .homepage-logos-section {
    padding: map_get($dimensions, xxl) * 3 0;
  }
}

.logos-info {
  max-width: 612px;
  margin: 0 auto;
  p {
    text-align: center;
    padding: 16px 0 40px 0;
    line-height: 24px;

    margin-top: 8px;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    text-align: center;

    color: #514c71;
  }
}
