@import "styles/general";

.brands-section {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 75rem;
  background-color: map_get($colors, foreground-dark-6);
  
  padding: map_get($dimensions, l) * 2 map_get($dimensions, l);
  position: relative;

  .brands-section__title {
    @include text-style(
            $color: transparentize(map_get($colors, background), .13),
            $font-size: map_get($dimensions, l),
            $font-family: $font-Raleway,
            $font-weight: 500,
            $line-height: map_get($dimensions, xl)
    );
    text-align: center;
    margin: 0;
  }

  .alice-carousel__stage-item {
    img {
      display: block;
      width: 100%;
    }
  }

  .alice-carousel__dots {
    margin-top: 0;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .brands-section {
    padding: map_get($dimensions, xxl) * 2 map_get($dimensions, l);
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .brands-section {
    padding:  map_get($dimensions, xxl) * 3 0;
  }
}