@import "styles/general";

.overflow-hidden-container {
  overflow-x: hidden;
}

.techSnapshot-section {
  position: relative;

  > img {
    position: absolute;
    z-index: 2;
  }
}

.techSnapshot-decorative {
  max-width: 280px;
  width: 30%;
  top: -3%;
  right: 5%;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .techSnapshot-decorative {
    width: 20%;
    top: -5%;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg))  {
  .techSnapshot-decorative {
    top: -10%;
    right: 1%;
    width: 25%;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl))  {
  .techSnapshot-decorative {
    top: -10%;
    right: 5%;
    width: 30%;
  }
}

