@import "styles/general";

.article-title {
  @include text-style(
          $color: map_get($colors, accent-10),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, l),
          $font-weight: 700,
          $line-height: map_get($dimensions, base) * 2
  );
  margin: 0;
}

.article-date {
  @include text-style(
          $color: transparentize(map_get($colors, background), .62),
          $font-family: $font-Roboto,
          $font-size: map_get($dimensions, base),
          $font-weight: 400,
          $line-height: map_get($dimensions, l)
  );
  display: block;
  margin: map_get($dimensions, xs) 0 map_get($dimensions, m);
}

.article-cover {
  width: 100%;
}

.author-avatar-container {
  border-radius: 50%;
  display: block;
  height: 3.5rem;
  margin: map_get($dimensions, l) auto 0;
  overflow: hidden;
  width: 3.5rem;
}

.author-avatar {
  width: 100%;
}

.author-name {
  @include text-style(
          $color: transparentize(map_get($colors, background), .13),
          $font-family: $font-Roboto,
          $font-size: map_get($dimensions, base),
          $font-weight: 400,
          $line-height: map_get($dimensions, l)
  );
  display: block;
  margin-top: map_get($dimensions, xs);
  text-align: center;
  width: 100%;
}

.author-position {
  @include text-style(
          $color: transparentize(map_get($colors, background), .13),
          $font-family: $font-Roboto,
          $font-size: map_get($dimensions, sm),
          $font-weight: 400,
          $line-height: map_get($dimensions, l)
  );
  display: block;
  text-align: center;
}

.article-description {
  @include text-style(
          $color: map_get($colors, background),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, l),
          $font-weight: 500,
          $line-height: map-get($dimensions, base) * 2
  );
  margin-top: map-get($dimensions, m);
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .article-title {
    margin-top: map_get($dimensions, xl) * 2;
  }

  .author-avatar-container {
    height: 4.5rem;
    width: 4.5rem;
  }
}