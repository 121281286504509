@import "styles/general";

.card {
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,.16);
  box-sizing: border-box;
  overflow: hidden;
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .card {
    transition: all .25s ease-in-out;

    &:hover {
      box-shadow: 0 4px 16px 0 map_get($colors, primary-light-5);
      transition: all .25s ease-in-out;
    }
  }
}