@import "styles/general";

.grid {
  display: grid;
  grid-gap: map_get($dimensions, base);
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
