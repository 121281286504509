@import "styles/general";

.footer {
  background-color: map_get($colors, foreground);
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: map_get($dimensions, m) * 2 0;
}

.footer-logos {
  display: flex;
  justify-content: space-between;
}

.footer-external-logos {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 56px;
  gap: 16px;
  align-items: center;
  a {
    display: inline;
  }
  img {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
}

.connatix-logo {
  max-width: 180px;
}

.footer-menu__social {
  // position: absolute;
  // bottom: 65px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  a {
    margin-right: map_get($dimensions, m);
  }
}

.footer-middle {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.footer-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-title {
  @include text-style(
    $color: map_get($colors, accent-9),
    $font-family: $font-Raleway,
    $font-size: map_get($dimensions, m),
    $font-weight: 600,
    $line-height: map_get($dimensions, xxl)
  );
  margin-top: map_get($dimensions, m) * 2;
  margin-bottom: map_get($dimensions, m) / 2;
}

.footer-description {
  @include text-style(
    $color: map_get($colors, accent-14),
    $font-family: $font-Raleway,
    $font-size: map_get($dimensions, sm),
    $font-weight: 500,
    $line-height: map_get($dimensions, m)
  );
  margin-bottom: map_get($dimensions, xl);
  width: 100%;
}

.footer-menu {
  display: flex;
  max-width: 520px;
}

.footer-menu__navigation {
  display: flex;
  flex-wrap: wrap;
}

.footer-menu__section {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 130px;
}

.footer-menu__section__title {
  @include text-style(
    $color: map_get($colors, accent-13),
    $font-family: $font-Raleway,
    $font-size: 13px,
    $font-weight: 600,
    $line-height: map_get($dimensions, base)
  );
  text-decoration: none;
  padding-top: map_get($dimensions, l);
}

.footer-menu__link {
  @include text-style(
    $color: map_get($colors, accent-13),
    $font-family: $font-Raleway,
    $font-size: map_get($dimensions, xs),
    $font-weight: 400,
    $line-height: map_get($dimensions, base)
  );
  display: block;
  text-decoration: none;
  padding: map_get($dimensions, xs) / 2 0;

  img {
    width: 20px;
    cursor: pointer;
  }
}

.footer__copyright {
  @include text-style(
    $color: map_get($colors, background-light-3),
    $font-family: $font-Raleway,
    $font-size: 13px,
    $font-weight: 400,
    $line-height: map_get($dimensions, m)
  );
  width: 70%;
  margin-top: map_get($dimensions, m) * 4;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .footer-content {
    padding: map_get($dimensions, xxl) * 2 0;
  }

  .footer-title {
    margin-top: map_get($dimensions, m) * 3;
  }

  .footer-menu__social {
    position: static;
  }

  .footer__copyright {
    margin-top: map_get($dimensions, m) * 2;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .footer-description {
    max-width: 150px;
  }
  .footer-external-logos {
    margin-bottom: 60px;
  }

  .footer-middle {
    flex-direction: row;
  }

  .footer-menu__navigation {
    margin-top: map_get($dimensions, m) * 3;
  }

  .footer-menu__section {
    margin: 0;
  }
}
