@import "styles/general";

.animation-section {
    background-color: #eff3f5;
    padding: 32px;
    z-index: 0;
    position: relative;
    .title-decoration {
        margin: 0 auto;
        width: 150px;
        height: 4px;
        background-color: rgb(255, 204, 0);
    }
    h2 {
        text-align: center;
        line-height: 40px;
        font-size: 32px;
        margin-bottom: 100px;
        font-weight: 600;
        color: #000;

        margin-top: 24px;
    }
    &__container {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        position: relative;
        margin-bottom: 40px;

        &.first {
            .circle {
                &::before {
                    content: "";
                    width: 1px;
                    background-color: #bebebe;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 100vh;
                    max-height: unset !important;
                }
            }
        }
        &.second {
            .circle {
                &::before {
                    content: "";
                    width: 1px;
                    background-color: #bebebe;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 100vh;
                    height: 100vh;
                    max-height: unset !important;
                }
            }
        }

        &.third {
            .circle {
                &::before {
                    content: "";
                    width: 1px;
                    background-color: #bebebe;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 100vh;
                    max-height: unset !important;
                }
            }
        }

        &.fourth {
            .circle {
                &::before {
                    content: "";
                    width: 1px;
                    background-color: #bebebe;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 100vh;
                    max-height: 210px !important;
                    top: -210px !important;
                }
            }
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 47px;
        span {
            display: flex;
            span {
                top: -8px;
            }
            .circle {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background-color: #d9d9d9;
                position: absolute;
                left: -25px;
                &::before {
                    content: "";
                    width: 1px;
                    background-color: #bebebe;
                    position: absolute;
                    top: 0;
                    z-index: -1;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 100vh;
                    max-height: 380px;
                }
            }
            color: #514c71;
            font-size: 18px;
            line-height: 24px;
            font-weight: 500;
            position: relative;
        }
        h3 {
            margin-top: 15px;
            margin-bottom: 19px;
            font-weight: 600;

            color: #0d192d;

            font-size: 1.25rem;

            line-height: 1.5rem;
        }
        p {
            margin: 0;
            font-weight: 400;
            color: #514c71;

            font-size: 1.125rem;

            line-height: 1.5rem;
        }
    }

    .animation-canvas {
        max-height: 540px;
        svg {
            max-height: 540px;
        }
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
    .animation-section {
        h2 {
            font-family: "Raleway";
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            line-height: 40px;
     

            text-align: center;

            color: #0d192d;
        }
        padding: 60px;
        &__container {
            flex-wrap: nowrap;
            max-height: 540px;
            max-width: 75rem;
            margin: 0 auto;
            margin-bottom: 120px;

            .circle {
                width: 12px !important;
                height: 12px !important;
                left: -35px !important;
            }
            &.second {
                .circle {
                    &::before {
                        content: "";
                        width: 1px;
                        background-color: #bebebe;
                        position: absolute;
                        top: 0;
                        z-index: -1;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 100vh;
                        max-height: 540px !important;
                        top: -180px !important;
                    }
                }
            }

            &.third {
                .circle {
                    &::before {
                        content: "";
                        width: 1px;
                        background-color: #bebebe;
                        position: absolute;
                        top: 0;
                        z-index: -1;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 100vh;
                        max-height: 570px !important;
                        top: -180px !important;
                    }
                }
            }

            &.fourth {
                .circle {
                    &::before {
                        content: "";
                        width: 1px;
                        background-color: #bebebe;
                        position: absolute;
                        top: 0;
                        z-index: -1;
                        left: 50%;
                        transform: translateX(-50%);
                        height: 100vh;
                        max-height: 210px !important;
                        top: -210px !important;
                    }
                }
            }
        }

        .text-container {
            flex: 1 1 50%;
            margin-bottom: 0;

            h3 {
                max-width: 359px;
                font-size: 32px;
                line-height: 38px;
            }
            p {
                max-width: 420px;
                font-size: 20px;
            }
        }

        .animation-canvas {
            flex: 1 1 50%;
        }
    }
}

.metrics-section {
    display: flex;
    justify-content: space-evenly;
    padding: 100px 0;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;

    .title-decoration {
        margin: 0 auto;
        width: 150px;
        height: 4px;
        background-color: rgb(255, 204, 0);
    }
    h2 {
        text-align: center;
        font-size: 32px;
        font-weight: 600;
        margin-bottom: 60px;
    }

    .metrics-container {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        flex-wrap: wrap;
    }

    .metric {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px;
        max-width: 120px;
        img {
            height: 80px;
            min-width: 94px;
            max-width: 120px;
            margin-bottom: 24px;
        }
    }
    h3 {
        font-size: 56px;
        font-weight: 600;
        line-height: 66px;
        color: rgba(0, 0, 0, 0.87);
        margin: 0;
        margin-bottom: 4px;
    }

    span {
        line-height: 24px;
        font-weight: 500;
        text-align: center;
        max-width: 136px;
    }
}

.metrics-section {
    .footnote {
        font-size: 12px;
        max-width: unset;
        color: #979797;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        margin-top: 40px;
        padding: 0 24px;
        font-family: $font-Roboto;
    }
}

.logos-wrapper {
    background-color: #eff3f5;
}

.second {
    margin-bottom: 60px;
}

.animation2 {
    position: relative;
    span {
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 23233123123;
        text-align: center;
        font-size: 13px;
        color: #979797;
        font-family: $font-Roboto;
        font-weight: 400;
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
    .second {
        margin-bottom: 40px;
    }
    .animation2 {
        span {
            bottom: -20px;
        }
    }
}
