@import "styles/general";

.reasons-list {
  display: grid;
  grid-row-gap: map_get($dimensions, xxl);
  margin: map_get($dimensions, xxl) 0;
}

@media only screen and (min-width: map_get($breakpoints, screen-sm)) {
  .reasons-list {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: map_get($dimensions, xxl);
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .reasons-list {
    grid-gap: map_get($dimensions, m) * 5;
    margin: map_get($dimensions, m) * 5 0;
  }
}