@import "styles/general";

#content-cms {

  :target::before {
    content: '';
    display: block;
    height: 5rem;
    margin-top: -5rem;
  }

  .content-cms-grid {
    display: grid;
    grid-gap: map_get($dimensions, base);
  }

  h1 {
    @include text-style($color: map_get($colors, background),
      $font-family: $font-Raleway,
      $font-size: map_get($dimensions, xxl),
      $font-weight: 700,
      $line-height: map_get($dimensions, m) * 2);
    margin: map_get($dimensions, xs) 0;
  }

  h2 {
    @include text-style($color: map_get($colors, background),
      $font-family: $font-Raleway,
      $font-size: map_get($dimensions, l),
      $font-weight: 700,
      $line-height: map_get($dimensions, m) * 2);
    margin: map_get($dimensions, sm) 0;
  }

  h3 {
    @include text-style($color: map_get($colors, background),
      $font-family: $font-Raleway,
      $font-size: map_get($dimensions, m),
      $font-weight: 700,
      $line-height: map_get($dimensions, xxl));
    margin: map_get($dimensions, sm) 0;
  }

  h4 {
    @include text-style($color: map_get($colors, background),
      $font-family: $font-Raleway,
      $font-size: map_get($dimensions, base),
      $font-weight: 700,
      $line-height: map_get($dimensions, m) * 2);
    margin: map_get($dimensions, m) 0;
  }

  h5 {
    @include text-style($color: map_get($colors, background),
      $font-family: $font-Raleway,
      $font-size: map_get($dimensions, sm),
      $font-weight: 700,
      $line-height: map_get($dimensions, m) * 2);
    margin: map_get($dimensions, l) 0;
  }

  h6 {
    @include text-style($color: map_get($colors, background),
      $font-family: $font-Raleway,
      $font-size: map_get($dimensions, xs),
      $font-weight: 700,
      $line-height: map_get($dimensions, m) * 2);
    margin: map_get($dimensions, xl) 0;
  }

  p {
    @include text-style($color: transparentize(map_get($colors, background), .46),
      $font-family: $font-Roboto,
      $font-size: map_get($dimensions, m),
      $font-weight: 300,
      $line-height: map_get($dimensions, xxl));
    margin: map_get($dimensions, base) 0;
  }

  a {
    @include text-style($color: map_get($colors, primary),
      $font-family: $font-Roboto,
      $font-size: map_get($dimensions, m),
      $font-weight: 300,
      $line-height: map_get($dimensions, xxl));
  }

  ol {
    @include text-style($color: transparentize(map_get($colors, background), .46),
      $font-family: $font-Roboto,
      $font-size: map_get($dimensions, base),
      $font-weight: 300,
      $line-height: map_get($dimensions, xxl));
    margin: map_get($dimensions, xl) 0 0;
    padding: 0;
    list-style: none;
    counter-reset: custom-counter;

    li::before {
      content: counters(custom-counter, ".") ".";
      counter-increment: custom-counter;
      margin-right: 8px;
      font-size: 1.15rem;
      color: map_get($colors, primary);
      font-weight: 400;
    }

    ol {
      margin: 0 0 0 1.5rem;
    }
  }

  ul {
    @include text-style($color: transparentize(map_get($colors, background), .46),
      $font-family: $font-Roboto,
      $font-size: map_get($dimensions, base),
      $font-weight: 300,
      $line-height: map_get($dimensions, xxl));
    list-style: none;
    margin: map_get($dimensions, xl) 0 0;
    padding: 0 0 0 map_get($dimensions, l);

    li {
      margin: map_get($dimensions, base) 0;
      position: relative;

      &::before {
        content: "\2022";
        color: map_get($colors, primary);
        font-size: map_get($dimensions, m) * 2;
        position: absolute;
        left: -1 * map_get($dimensions, l);
      }
    }
  }

  img {
    display: block;
    margin: map_get($dimensions, base) auto;
    width: 70%;
  }

  .divider {
    color: map_get($colors, primary);
    font-size: map_get($dimensions, base) * 3;
    margin-bottom: map_get($dimensions, l);
    letter-spacing: map_get($dimensions, l);
    text-align: center;
  }


  blockquote {
    align-items: flex-start;
    border-left: 4px solid map_get($colors, primary);
    display: flex;
    margin: map_get($dimensions, m) 0;
    padding: map_get($dimensions, base) / 2 map_get($dimensions, sm);

    p {
      margin: 0;
    }

    i {
      font: {
        size: map_get($dimensions, m) * 2;
        style: italic;
      }

      line-height: map_get($dimensions, xl);
      margin-right: map_get($dimensions, xs);
      transform: rotate(180deg);
    }

    .quote {
      @include text-style($color: transparentize(map_get($colors, background), .46),
        $font-family: $font-Roboto,
        $font-size: map_get($dimensions, base),
        $font-weight: 400,
        $line-height: map_get($dimensions, xxl));
      font-style: italic;
    }

    .author {
      @include text-style($color: map_get($colors, background),
        $font-family: $font-Raleway,
        $font-size: map_get($dimensions, sm),
        $font-weight: 600,
        $line-height: map_get($dimensions, m));
      display: block;
    }

    .position {
      @include text-style($color: transparentize(map_get($colors, background), .46),
        $font-family: $font-Roboto,
        $font-size: map_get($dimensions, xs),
        $font-weight: 300,
        $line-height: map_get($dimensions, m));
    }
  }


  #general,
  #videoplatform {

    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    margin: 0;
  }

  @media only screen and (max-width: 600px) {
    .section-header {
      svg {
        min-width: 24px !important;
        min-height: 24px !important;
        width: 24px;
        height: 24px;
      }

      h1 {
        font-size: 20px !important;
        line-height: 150% !important;
      }
    }
  }

  .section-header {
    display: flex;
    align-items: center;
    background: #F0F3F5;
    padding: 21.5px;
    height: 30px;
    max-width: 634px;
    margin-top: 44px;
    
    &#videoPlatformSectionHeader {
      margin-bottom: 44px;
    }

    svg {
      min-width: 44px;
      min-height: 44px;
    }

    &.fixed-to-top {
      position: fixed;
      top: 70px;
      z-index: 1;
      width: calc(100% - 91px);
      margin-top: 0 !important;
    }

    svg {
      margin-right: 10px;
    }
  }

  .in-page-nav {
    font-family: 'Roboto', 'sans-serif';

    div {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #0099FF;
      font-weight: 400;
      font-size: 20px;
      line-height: 150%;
      font-family: 'Roboto', 'sans-serif';
    }

    a {
      color: #0099FF !important;
      font-size: 20px;
      line-height: 150%;
      font-family: 'Roboto', 'sans-serif';
    }


    div:first-of-type {
      margin-bottom: 20px;
    }

  }

  .anchor {
    display: block;
    visibility: hidden;
    position: relative;
    top: -150px; //adjust this to determine where the page jumps to when clicking the general privacy link
  }

  .anchor#videoPlatformPrivacy {
    top: -1000px; //adjust this to determine where the page jumps to when clicking the video platform link
  }


}