@import "styles/general";

.open-position-list {
  background: url("assets/careers/open-positions-background.png") top no-repeat;
  background-size: 100%;
  box-sizing: border-box;
  padding: map_get($dimensions, m) * 2 0;
}

.open-position-list__select-container {
  margin-bottom: map_get($dimensions, m)  * 2;
}

.open-position-list__select-item {
  display: inline-block;
  position: relative;
  width: 100%;

  &:last-of-type {
    margin-top: map_get($dimensions, xxl);
  }
}

.open-position-list__select {
  background-color: map_get($colors, foreground);
  @include text-style(
          $color: map_get($colors, background),
          $font-size: map_get($dimensions, base),
          $font-family: $font-Roboto,
          $font-weight: 400,
          $line-height: map_get($dimensions, xl)
  );
  border: none;
  outline: none;
  -webkit-appearance: none;
  width: 100%;
}

.open-position-list__select-item::after {
  color: map_get($colors, accent-3);
  content: '\25bc';
  position: absolute;
  right: 0;
  top: 7px;
}

.select-decoration {
  background-color: map_get($colors, accent-3);
  height: 1px;
  margin-top: map_get($dimensions, base);
}

@media only screen and (min-width: map_get($breakpoints, screen-sm)) {
  .open-position-list__select-item {
    margin-right: map_get($dimensions, m);
    width: 200px;

    &:last-of-type {
      margin-top: 0;
    }
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .open-position-list {
    padding: map_get($dimensions, m) * 3 0;
  }

  .open-position-list__select-container {
    margin-bottom: map_get($dimensions, m) * 3;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .open-position-list {
    padding: map_get($dimensions, m) * 4 0;
  }

  .open-position-list__select-item {
    margin-right: map_get($dimensions, xxl);

    &:last-of-type {
      margin-top: 0;
    }
  }

  .open-position-list__select {
    font-size: map_get($dimensions, m);
  }
}