@mixin button() {
  -webkit-border-radius: map_get($dimensions, m);
  -moz-border-radius: map_get($dimensions, m);
  border-radius: map_get($dimensions, m);
  font: {
    family: $font-Raleway;
    size: map_get($dimensions, sm);
    weight: 600;
  }
  padding: map_get($dimensions, xs) map_get($dimensions, m);
  text: {
    align: center;
    decoration: none;
    transform: uppercase;
  }
}


@mixin primary-button() {
  @include button();
  background-color: map_get($colors, primary);
  color: transparentize(map_get($colors, foreground), .13);

  &:hover {
    background-color: map_get($colors, primary-light-1);
    color: map_get($colors, foreground);
  }
}


@mixin text-button() {
  @include button();
  color: map_get($colors, primary);

  &:hover {
    background-color: map_get($colors, primary-light-4);
  }
}


@mixin outline-button() {
  @include button();
  border: 1px solid map_get($colors, primary);
  border-radius: map_get($dimensions, m);

  &:hover {
    background-color: transparentize(map_get($colors, foreground), .36);
  }
}