@import "styles/general";

.carousel-section {

  position: relative;

  .alice-carousel__wrapper {
    overflow: visible;
  }

  .alice-carousel__dots {    
    padding-bottom: map_get($dimensions, l) * 2;
  }

  .button {
    @include primary-button();
    display: inline-block;
    cursor: pointer;
  }

  .decorative__image {
    position: absolute;
  }
  
}

@media only screen and(min-width: map_get($breakpoints, screen-md)) {
  .carousel-section {
    .alice-carousel__dots {
      padding-bottom: map_get($dimensions, xxl) * 2;
    }

    .button {
      margin-top: map_get($dimensions, l);
    }
  }
}

@media only screen and(min-width: map_get($breakpoints, screen-lg)) {
  .carousel-section .alice-carousel__dots {
    padding-bottom: map_get($dimensions, xxl) * 3;
  }
}