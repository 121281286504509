@import "styles/general";

.animated-title {
  @include text-style(
          $color: transparentize(map_get($colors, background), .13),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, l),
          $font-weight: 700,
          $line-height: map_get($dimensions, l)
  );
  margin-bottom: 0;
  padding: map_get($dimensions, l) 0;
}

.animated-title__word {
  color: map_get($colors, primary);
  margin-left: 5px;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .animated-title {
    font-size: map_get($dimensions, l) * 2;
    padding: map_get($dimensions, xxl) * 2 0;
  }

  .animated-title__word {
    margin-left: map_get($dimensions, xs);
  }
}