@import "styles/general";

.careers-header {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.careers-header__video {
  position: absolute;
  height: auto;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  top: 0;
  transform: translateX(-50%);
  width: auto;
  z-index: -1;
}

.careers-header__content {
  background-color: rgba(13,25,45,0.6);
  height: 100%;
}

.careers-blog-section {
  margin: map_get($dimensions, xxl) 0;
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .careers-blog-section {
    margin: map_get($dimensions, m) * 5 0;
  }
}