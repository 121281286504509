.homepage-container {
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 75rem;
  padding: 0 map_get($dimensions, l);
  position: relative;
}

.homepage-section__title {
  @include text-style($color: map_get($colors, foreground),
    $font-family: $font-Raleway,
    $font-size: map_get($dimensions, xl),
    $font-weight: 600,
    $line-height: map_get($dimensions, m) * 2);
  margin-top: map_get($dimensions, m) * 2;
}

.homepage-section__subtitle {
  @include text-style($color: map_get($colors, foreground),
    $font-size: 18px,
    $font-family: $font-Raleway,
    $font-weight: 400,
    $line-height: map_get($dimensions, l));
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .homepage-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .homepage-section__title {
    font-size: map_get($dimensions, xxl);
    line-height: map_get($dimensions, m) * 2;
    margin-top: map_get($dimensions, m) * 3;
    ;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .homepage-container {
    padding: 0 map_get($dimensions, m) * 2;
  }
}

.section-tag {
  text-transform: uppercase;
  color: #fff;
  border-bottom: 4px solid #fff;
  padding: 12px 16px 12px 0;
  width: fit-content;
}