@import "styles/general";

.home-header {
  height: calc(100vh - 4.5rem);
  position: relative;
  overflow: hidden;
  width: 100%;
}

.home-header--has-banner {
  height: calc(100vh - 12rem);
  position: relative;
  overflow: hidden;
  width: 100%;
}

.home-header__video {
  position: absolute;
  height: auto;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  top: 0;
  transform: translateX(-50%);
  width: auto;
  z-index: -1;
}

.home-header-container {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0 auto;
  padding: 0 map_get($dimensions, l);
  width: 100%;
}

.home-header__img {
  display: none;
}

.home-header__content {
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home-header__title {
  @include text-style(
          $color: map_get($colors, foreground),
          $font-size: map_get($dimensions, m) * 2,
          $font-family: $font-Raleway,
          $font-weight: 600,
          $line-height: map_get($dimensions, l) * 2
  );
  text-align: center;
  margin: 0;
}

.home-header__subtitle {
  @include text-style(
          $color: map_get($colors, foreground),
          $font-size: map_get($dimensions, m),
          $font-family: $font-Raleway,
          $font-weight: 400,
          $line-height: map_get($dimensions, xl)
  );
  text-align: center;
}

.home-header__link {
  @include primary-button();
  background-color: map_get($colors, foreground);
  color: map_get($colors, primary);
  display: inline-block;

  &:hover {
    background-color: map_get($colors, foreground-dark-1);
    color: map_get($colors, primary-light-6);
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .home-header-container {
    position: relative;
  }

  .home-header__img {
    display: block;
    max-width: 100%;
  }

  .home-header__content {
    position: absolute;
  }

  .home-header__title {
    font-size: map_get($dimensions, xl) * 2;
  }

  .home-header__subtitle {
    font-size: map_get($dimensions, l);
    margin: map_get($dimensions, xxl) 0;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .home-header__title {
    font-size: map_get($dimensions, m) * 4;
    line-height: map_get($dimensions, xl) * 3;
  }
}