@import "styles/general";

* {
    font-family: $font-Raleway;
}

.quote-container {
 
  
    .alice-carousel__stage {
        .alice-carousel__stage-item {
            opacity: 0;
            &.__active {
                opacity: 1;
            }
            transition: opacity 200ms ease 0ms; //this is both for when we click the arrows and also for when it slides byitself
        }
    
    }
}

.cnx-carousel-wrapper {
    padding: 100px 0;
    .alice-carousel__wrapper {
        mix-blend-mode: multiply;
    }
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        max-width: 564px;
        margin: 0 auto;
        padding: 0 24px;
    }

    h4 {
        font-weight: 600;
        color: #000;
        font-size: 32px;
        line-height: 40px !important;
        margin-top: 24px;
        margin-bottom: 0;
    }
    
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
    .cnx-carousel-wrapper__top {
        padding: 0;
    }
}



.btn-clicked {
    .alice-carousel__stage {

        transition: transform 400ms ease 0ms !important; //if we need to test the slide animation in relation to the
        //fadein/out animation we can change this value and the one for the opacity and just click through the slides to demo
    }
}

.bg-white {
    background-color: #fff;
}

.bg-gray {
    background-color: #f0f3f5;
}

.quote-container {
    max-width: 564px;
    margin: 56px auto 0 auto;
    position: relative;
    padding: 0 24px;
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
    .quote-container {
        padding: 0;
    }
}

.title-container {
    display: flex;
    flex-direction: column;
}
.title-decoration {
    height: 4px;
    background-color: #ffcc01;
    width: 57px;
}


.logo-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 43px;
    margin-bottom: 16px;
    a {
        display: flex;
        color: #666666 !important;
    }
    span {
        font-family: $font-Roboto;
        color: #666666;
        text-transform: uppercase;
    }
    .logo {
        &.inactive-link {
            pointer-events: none;
            cursor: default;
            text-decoration: none;
        }
        img {
            width: 100%;
            min-width: 43px;
            max-width: 314px;
            height: 100%;
            max-height: 43px;
        }
    }
}

.author {
    display: flex;
    align-items: center;
    margin-top: 28px;
}

.quote-block {
    max-height: 400px;
    .blockquotes-container {
        mix-blend-mode: multiply;
        position: absolute;
        max-width: 335px;
        height: 192px;       
        bottom: 0;
        right: 0;
        padding: 0 24px;
        z-index: -1; //it needs to appear under the text and avatar and name and role
        img {
            width: 100%;
            height: 100%;
        }
    }

    p {
        line-height: 30px;
        font-size: 16px;
        color: #292737;
        margin: 0;
    }
    @media only screen and (min-width: map_get($breakpoints, screen-lg)) {
        p {
            line-height: 36px;
            font-size: 18px;
        }
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
    .quote-block {
        .blockquotes-container {
            padding: 0;
        }
    }
}

.nav-btns-container {
    display: flex;
}

.nav-btn {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    width: 56px;
    height: 56px;

    img {
        width: 100%;
        height: 100%;
    }
    &:first-of-type {
        margin-right: 12px; //supposed to be 16px but it already has a 4px gap between the buttons by default
    }
    
}

.author-avatar {
    min-width: 74px;
    width: 74px;
    height: 74px;
    margin-right: 8px;
    border-radius: 50%;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}
@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
    .author-avatar {
        width: 72px;
        height: 72px;
        margin-right: 16px;
    }
}

.about-author {
    display: flex;
    flex-direction: column;
}

.author-name {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: map_get($colors, primary);
}

.author-role {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    color: map_get($colors, background-light-6);
}
