@import "styles/general";

.optout-wrapper {
  margin: map_get($dimensions, m) * 3 auto;
  max-width: 677px;

  &__divider {
    background: #B4B4B4;
    display: block;
    height: 1px;
  }

  &__preference-section {
    margin: 5rem 0;

    .preference-section__title {
      font-weight: 500;
    }

    .preference-section__note {
      font-size: map_get($dimensions, base);
      margin-top: 0;
    }
  }

  h1 {
    @include text-style($color: map_get($colors, background),
    $font-family: $font-Raleway,
    $font-size: map_get($dimensions, xxl),
    $font-weight: 700,
    $line-height: map_get($dimensions, m) * 2);
    margin: map_get($dimensions, xs) 0;
  }

  p {
    @include text-style($color: transparentize(map_get($colors, background), .46),
    $font-family: $font-Roboto,
    $font-size: map_get($dimensions, m),
    $font-weight: 300,
    $line-height: map_get($dimensions, xxl));
    margin: map_get($dimensions, base) 0;
  }

  a {
    @include text-style($color: map_get($colors, primary),
    $font-family: $font-Roboto,
    $font-size: map_get($dimensions, m),
    $font-weight: 300,
    $line-height: map_get($dimensions, xxl));
  }

  .button--primary {
    @include primary-button();
    cursor: pointer;
    display: inline-block;
    margin: map_get($dimensions, m) 0;
  }

  .button--disabled {
    @include primary-button();
    background-color: #979797;
    cursor: unset;
    display: inline-block;
    margin: map_get($dimensions, m) 0;
    pointer-events: none;
  }

  .switch--primary {
    align-items: center;
    display: flex;
    position: relative;

    &__input {
      border-radius: 100px;
      height: 36px;
      overflow: hidden;
      position: relative;
      top: 50%;
      width: 74px;
    }

    &__label {
      color: #333333;
      margin-left: .5rem;
    }

    &__knobs,
    &__layer {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &__checkbox {
      cursor: pointer;
      height: 100%;
      margin: 0;
      opacity: 0;
      padding: 0;
      position: relative;
      width: 100%;
      z-index: 3;
    }

    &__knobs {
      z-index: 2;

      &:before {
        background-color: #FFFFFF;
        border-radius: 50%;
        content: "";
        font-size: 10px;
        font-weight: bold;
        height: 10px;
        left: 4px;
        line-height: 1;
        padding: 9px 4px;
        position: absolute;
        text-align: center;
        top: 4px;
        transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
        width: 20px;
      }
    }

    &__layer {
      background-color: map_get($colors, accent-11);
      border-radius: 100px;
      transition: 0.3s ease all;
      width: 100%;
      z-index: 1;
    }

    .switch--primary__checkbox:checked + .switch--primary__knobs:before {
      background-color: #FFFFFF;
      content: "";
      left: 42px;
    }

    .switch--primary__checkbox:checked ~ .switch--primary__layer {
      background-color: map_get($colors, accent-7);
    }
  }

}
