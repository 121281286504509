@import "styles/general";

.flex-container {
  max-width: 64rem;
  position: relative;
  margin: 0 auto;
  padding: 0 map_get($dimensions, l);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .flex-container {
    padding: 0 map_get($dimensions, m) * 2;
  }
}

@media only screen and(min-width: map_get($breakpoints, screen-lg)) {
  .flex-container {
    flex-direction: column;
  }

  .row {
    flex-direction: row;
  }

  .row-reverse {
    flex-direction: row-reverse;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .flex-container {
    padding: 0;
  }
}