@import "styles/general";

.section-title__title-container {
  display: flex;
  flex-direction: column;
}

.section-title__title {
  @include text-style(
          $color: map_get($colors, background),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, m),
          $font-weight: 500,
          $line-height: map_get($dimensions, l)
  );
  text-transform: uppercase;
}