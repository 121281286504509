@import "styles/general";

.tooltip {
    position: absolute;
    top: -20px;
    left: 20px;
    background: rgba(0, 0, 0, 0.8);
    width: 418px;

    max-width: 70px;

    color: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    padding: 16px;
    border-radius: 8px;
    word-break: break-word;
    visibility: hidden;

    z-index: 1;
    &.ad-supported {
        left: -40px;
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-sm)) {
    .tooltip {
        max-width: 200px;
        &.ad-supported {
            left: -140px;
        }
    }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
    .tooltip {
        max-width: max-content;
        &.ad-supported {
            max-width: 200px;
            left: 20px;
        }
    }
}