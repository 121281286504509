@import "styles/general";
@import "../../section";

.homepage-highlights {
  background: url("../../../../assets/homepage/highlights-section-bkg.jpg") no-repeat center;
  background-size: cover;
}

.homepage-highlights__content {
  padding: 2.5rem 0;
  z-index: 3;
}

.homepage-highlights__list {
  @include text-style(
          $color: map_get($colors, foreground),
          $font-size: map_get($dimensions, base),
          $font-family: $font-Raleway,
          $font-weight: 400,
          $line-height: map_get($dimensions, xl)
  );
  padding-left: map_get($dimensions, base);
}

.homepage-highlights__link {
  @include primary-button();
  background-color: map_get($colors, foreground);
  color: map_get($colors, primary);
  display: inline-block;

  &:hover {
    background-color: map_get($colors, foreground-dark-1);
    color: map_get($colors, primary-light-6);
  }
}

.homepage-highlights__img-container {
  padding-bottom: 2.5rem;
}

.homepage-highlights__player {
  width: 100%;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {

  .homepage-highlights__content {
    width: 75%;
    padding: 4.5rem 0;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .homepage-highlights-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: relative;
  }

  .homepage-section__title {
    margin-top: 3rem;
  }

  .homepage-highlights__scroll-section {
    position: absolute;
    left: 0;
    top: map_get($dimensions, xxl) * 2;
  }

  .homepage-highlights__content {
    padding: 68px 0;
    width: 35%;
  }

  .homepage-highlights__img-container {
    padding-top: 7rem;
    padding-bottom: 4rem;
    width: 55%;
  }
}