@import "styles/general";
@import "../../section";

.homepage-video {
  background: url("../../../../assets/homepage/video-section-bkg.jpg") no-repeat center;
  background-size: cover;
}

.homepage-video__content {
  padding-top: map_get($dimensions, m) * 2;
}

.homepage-video__list {
  @include text-style(
          $color: map_get($colors, foreground),
          $font-size: map_get($dimensions, base),
          $font-family: $font-Raleway,
          $font-weight: 400,
          $line-height: map_get($dimensions, xl)
  );
  padding-left: map_get($dimensions, base);
}

.homepage-video__link {
  @include primary-button();
  background-color: map_get($colors, foreground);
  color: map_get($colors, primary);
  display: inline-block;

  &:hover {
    background-color: map_get($colors, foreground-dark-1);
    color: map_get($colors, primary-light-6);
  }
}

.homepage-video__img-container {
  position: relative;
}

.homepage-video__img {
  display: block;
  margin-left: 3px;
  width: 100%;
}

.homepage-video__video-player {
  position: absolute;
  top: 21%;
  width: 100%;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {

  .homepage-video__content {
    position: relative;
    z-index: 3;
    width: 75%;
    padding-top: map_get($dimensions, l) * 3;
  }

  .homepage-video__img-container {
    margin-top: -6rem;
    z-index: 1;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .homepage-video-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .homepage-video__content {
    padding-top: 68px;
    width: 35%;
  }

  .homepage-video__img-container {
    margin-top: 0;
    width: 58%;
  }

  .homepage-section__title {
    margin-top: 80px;
  }
}