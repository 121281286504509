@import "styles/general";

.privacy-policy {
  margin: map_get($dimensions, m) * 3 auto;
  max-width: 677px;

  p,
  a,
  ol,
  ul,
  li,
  li::before, strong, td {
    font-family: 'Roboto', sans-serif !important;
  }

  p {
    font-weight: 300 !important;
    font-size: 20px !important;
    line-height: 150% !important;
  }

  h2 {
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    text-decoration: underline;
  }

  strong p, strong {
    font-weight: 500 !important;
  }

  ol,
  ul {
    font-weight: 300 !important;
    font-size: 20px !important;
    line-height: 150% !important;
    margin: 0 !important;

    li::before {
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }

  a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.54) !important;
  }

  div:has(table) {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #EBEBEB;

    }

    //   /* Handle */

    &::-webkit-scrollbar-thumb {
      background-color: #C4C4C4;
      border-radius: 13px;

    }
  }

  ol:has(a[href="/privacy-policy#general"]) {
    a {
      color: #0099FF !important;
    }
    li::before {
      color: #0099FF !important;
    }
  }

  table {
    border-collapse: collapse;

    thead {
      tr {
        border-bottom: 1px solid #B4B4B4 !important;
      }
    }

    tr {
      border-bottom: 1px solid #B4B4B4;

      &:last-of-type {
        border-bottom: none;
      }
    }

    th,
    td {
      text-align: left;
      vertical-align: top;
    }

    th {
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      padding-bottom: 10px;
      width: 214px;
      min-width: 214px;

    }

    td {
      font-weight: 300;
      font-size: 18px;
      line-height: 150%;
      padding: 30px 8px;
      color: rgba(0, 0, 0, 0.54);

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }
}