@import "styles/general";

.leadership-cards-list {
  background: url("assets/leadership/leadership-background.png") no-repeat;
  background-size: 100% 100%;
  margin: map_get($dimensions, xl) 0;
}

.leadership-owners-list {
  display: grid;
  grid-gap: map_get($dimensions, base);
  margin-bottom: map_get($dimensions, base);
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .leadership-cards-list {
    margin: map_get($dimensions, base) * 3 0;
  }

  .leadership-owners-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .leadership-cards-list {
    margin: map_get($dimensions, m) * 4 0;
  }
}