@import "styles/general";

#tech-snapshot-section {

  .iMnhxj {
    border: none;

    ul {
      display: flex;
      overflow-x: auto;
      flex-direction: column;
      justify-content: flex-start;
      background-image: url(../../../../../assets/img_repetable_bkg.png);
      font-size: map_get($dimensions, s);
      line-height: map_get($dimensions, m);
      padding: map_get($dimensions, m) map_get($dimensions, xs) map_get($dimensions, xs);
      margin-bottom: map_get($dimensions, l);
      
      li {
        position: relative;
        padding: map_get($dimensions, base) 0 0;
        
        a {
          @include text-style(
            $color: map_get($colors, accent-10),
            $font-family: $font-Raleway,
            $font-size: map_get($dimensions, base),
            $font-weight: 500,
            $line-height: map_get($dimensions, l)
          );
          position: relative;
          text-decoration: none;
          padding: 0 0 0 map-get($dimensions, l);

          &:hover {
            color: map_get($colors, primary);
            cursor: pointer;
          }
        }
      }
    }

    .selected {
      a {
        color: map_get($colors, primary) !important;
        text-decoration: none !important;
        font-weight: 600;
      }

      a::before {
        content: '';
        background-size: cover;
        background-image: url('../../../../../assets/arrow.svg');
        width: 12px;
        height: 12px;
        position: absolute;
        left: 5px;
        bottom: 4px;
      }
    }
  }

  .tab {
    padding: 0;
  }

  .tech-snapshot-tab {    
    display: grid;
    grid-gap: map_get($dimensions, base);
    width: 100% !important;
  }

  .tech-snapshot-element {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: map_get($colors, foreground);
    border: 1px solid #D2DBDF;
    border-radius: 4px;
    color: map_get($colors, accent-10);
    box-sizing: border-box;
    padding: map_get($dimensions, xxl) map_get($dimensions, xl) map_get($dimensions, l);
    justify-content: space-between;
    height: 140px;
  }
  
  .tech-snapshot-element--big {
    height: 218px;
  }

  .tech-snapshot-element__text {
    @include text-style(
            $color: map_get($colors, accent-10),
            $font-family: $font-Raleway,
            $font-size: 1.125rem,
            $font-weight: 400,
            $line-height: map_get($dimensions, xxl)
    );
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  #tech-snapshot-section {
    .iMnhxj { 
      ul {
        flex-direction: row;
        justify-content: space-between;
        padding: map_get($dimensions, xxl) map_get($dimensions, l) map_get($dimensions, base);
        white-space: nowrap;
        li {
          padding: 0;
          margin: 0;

          a {
            padding: 0;
            font-size: map_get($dimensions, base);
          }

          &.selected a {
            text-decoration: underline;
            
            &::before {
              content: none;
            }

          }
        }
      }
    }

    .tech-snapshot-tab {
      grid-template-columns: repeat(2, 1fr);
    }

    .tech-snapshot-element__text {
      white-space: nowrap;
    }
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  #tech-snapshot-section {
    .iMnhxj {      
      display: flex;
      justify-content: space-between;
      display: grid;
      grid-template-columns: 37% 60%;

      ul {        
        padding: 0 map_get($dimensions, xxl);
        justify-content: center;
        height: 100%;
        flex-direction: column;
        white-space: nowrap;
        line-height: map_get($dimensions, l) * 2;

        li a {
           font-size: map_get($dimensions, m);
           padding-left: map-get($dimensions, l);
          line-height: map_get($dimensions, l) * 2;
        }

        li.selected a::before {
          left: 0;
          bottom: 6px;
          content: '';
        }
      }

      .tech-snapshot-tab {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
