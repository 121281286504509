@import "styles/general";

.reason__number {
  @include text-style(
          $color: map_get($colors, accent-2),
          $font-size: map_get($dimensions, m) * 3,
          $font-family: $font-Raleway,
          $font-weight: 300,
          $line-height: map_get($dimensions, m) * 2
  );
}

.reason__title {
  @include text-style(
          $color: map_get($colors, background),
          $font-size: map_get($dimensions, l),
          $font-family: $font-Raleway,
          $font-weight: 700,
          $line-height: map_get($dimensions, m) * 2
  );
  margin: 0;
}

.reason__description {
  @include text-style(
          $color: transparentize(map_get($colors, foreground-dark-7), .13),
          $font-size: map_get($dimensions, base),
          $font-family: $font-Roboto,
          $font-weight: 400,
          $line-height: map_get($dimensions, xl)
  );
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .reason {
    display: flex;
  }

  .reason__number {
    font-size: map_get($dimensions, m) * 4;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .reason {
    max-width: 350px;
  }
}

