@import "styles/general";

.header-content__logo {
  width: 206px;
  height: 48px;
  padding-top: map_get($dimensions, m) * 2;
  margin: 0;
}

.header-content__info-container {
  display: flex;
  justify-content: center;
}

.header-content__info {
  width: 90%;
  padding-top: 2.5rem;
}

.header-content__title {
  @include text-style(
    $color: transparentize( map_get($colors, foreground), .13),
    $font-family: $font-Raleway,
    $font-size: map_get($dimensions, xxl),
    $font-weight: 600,
    $line-height: map_get($dimensions, xxl)
  );
  margin: 0;
  padding-top: map_get($dimensions, m) * 2;
}

.header-content__description {
  @include text-style(
          $color: transparentize( map_get($colors, foreground), .13),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, base),
          $font-weight: 400,
          $line-height: map_get($dimensions, m) * 2
  );
  line-height: 150%;
  margin: map_get($dimensions, m) 0;
}

.header-content__btn {
  @include primary-button();
  display: inline-block;
  cursor: pointer;
}

.header-content__player-container {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: map_get($dimensions, l) 0 map_get($dimensions, m) * 2;
}

.header-content__player-background {
  background-image: url(../../../assets/img_repetable_bkg.png);
  background-size: contain;
  width: 90%;
  padding: 5% 0;
  min-height: 100px;
}
  
.header-content__player {
  margin-left: -2.5%;
  width: calc(100% + 5%);
}
// @media only screen and (min-width: map_get($breakpoints, screen-sm)) {
//   .header-content__title {
//     white-space: nowrap;
//   }
// }


@media only screen and (min-width: map_get($breakpoints, screen-md)) {


  .header-content__description {
    font-size: map_get($dimensions, l);
    line-height: map_get($dimensions, xxl);
  }

  .header-content__player-background {
    min-height: 335px;
  }
  .video-delivery, .video-analytics, .video-creation, .advertisers, .video-monetization {
    .header-content__player-background {
      min-height: 275px;
    }
  }

}

//62rem
@media only screen and (min-width: map_get($breakpoints, screen-lg)) {
  .header-content__info {
    padding-top: 0;
  }
  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: calc(100vh - 4.5rem);
    max-height: 750px;
  }

  .video-delivery {
    .header-content__info-container {
      width: 38%;
    }
    .header-content__player-container {
      width: 57%;
    }
 
  }

  .video-monetization {
    .header-content__info-container {
      width: 39%;
    }
    .header-content__player-container {
      width: 56%;
    }
  }

  .video-analytics, .video-creation {
    .header-content__info-container {
      width: 37%;
    }
    .header-content__player-container {
      width: 58%;
    }
  }

  .advertisers {
    .header-content__info-container {
      width: 35%;
    }
    .header-content__player-container {
      width: 60%;
    }
  
  }

  .video-delivery, .video-monetization, .video-creation, .video-analytics, .advertisers, .contextual {
    .header-content__info-container {
      margin-top: 10.5rem
    }
  }
  
  .header-content__info-container {
    width: 35%;
    margin-top: map_get($dimensions, xxl) * 2.75;
    padding: 0;
  }

  .header-content__logo {
    padding-top: map_get($dimensions, xxl) * 2;
  }

  .header-content__title {
    padding-top: 36px;
    line-height: 40px;
  }

  .header-content__description {
    width: 100%;
    margin: map_get($dimensions, xl) 0 map_get($dimensions, l);
    margin-top: 18px;
  }

  .header-content__player-container {
    padding: map_get($dimensions, xxl) * 2.75 0;
    min-height: 362px;
    width: 60%;
    justify-content: flex-end;
  }

  .header-content__player {
    margin-left: -2rem;
    width: 100% ;
  }
}

@media only screen and (min-width: map_get($breakpoints, screen-xl)) {
  .header-content__logo {
    padding-top: map_get($dimensions, xxl) * 3;
  }

  .header-content__title {
    padding-top: 36px;
  }
}

.header-tag {
  text-transform: uppercase;
  color: #fff;
  border-bottom: 4px solid #fff;
  padding: 12px 16px 12px 0;
  width: fit-content;
}



