@import "styles/general";

.member-container {
  margin: map_get($dimensions, l) * 2 auto;
  max-width: 500px;
  width: 100%;
}

.member-header {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.member__photo-container {
  height: 175px;
  width: 175px;
}

.member__photo {
  border-radius: 4px;
  height: 100%;
  width: 100%;
}

.member__name {
  @include text-style(
          $color: map_get($colors, accent-3),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, xl),
          $font-weight: 600,
          $line-height: map_get($dimensions, xxl)
  );
  margin: map_get($dimensions, xl) 0 map_get($dimensions, base);
}

.member__position {
  @include text-style(
          $color: transparentize(map_get($colors, background), .46),
          $font-family: $font-Roboto,
          $font-size: map_get($dimensions, m),
          $font-weight: 400,
          $line-height: map_get($dimensions, xl)
  );
  margin: 0 0 map_get($dimensions, base);
  text-align: center;
  text-transform: uppercase;
}

.member__social-links {
  display: flex;
  justify-content: center;
}

.member__social-link {
  padding: map_get($dimensions, base);
}

.member-description {
  margin: map_get($dimensions, xxl) 0 0;
}

@media only screen and (min-width: map_get($breakpoints, screen-md)) {
  .member-header {
    align-items: stretch;
    flex-direction: row;
  }

  .member__photo-container {
    min-height: 175px;
    min-width: 175px;
  }

  .member-details {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    margin-left: map_get($dimensions, base);
  }

  .member__name {
    margin: map_get($dimensions, base) 0 0 map_get($dimensions, base);
  }
  .member__position {
    text-align: left;
    margin-left: map_get($dimensions, base);
  }

  .member__social-links {
    align-items: flex-end;
    flex-grow: 1;
    justify-content: flex-start;
  }
}