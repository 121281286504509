@import "../../styles/general";

.two-row-text-section {
  padding-top: map_get($dimensions, xl) * 2;
}

.two-row-text-section--padding-bottom {
  padding-bottom: map_get($dimensions, xl) * 2;
}

.section__img {
  background-image: url(../../assets/img_repetable_bkg.png);
  background-size: contain;
  padding: 0 2rem;
  display: flex;
  justify-content: center;
  z-index: 1;
  
  img {
    width: 100%;
    height: 100%;
  }

  .image__desktop {
    display: none;
  }

  .image__mobile {
    display: block;
  }
}

.section__content {
  width: 100%;
  background-color: inherit;
  z-index: 2;
}

.section__text {
  width: 100%;
  margin-bottom: map_get($dimensions, base);
  z-index: 2;
}

.button {
  @include primary-button();
  display: inline-block;
}

.section__title {
  @include text-style(
          $color: map_get($colors, background-light-3),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, sm),
          $font-weight: 400,
          $line-height: map_get($dimensions, m)
  );
  text-transform: uppercase;  
  margin: map_get($dimensions, xs) 0;
}

.section__description-decoration {
  height: 4px;
  width: 150px;
}

.section__description {
  @include text-style(
          $color: map_get($colors, accent-10),
          $font-family: $font-Raleway,
          $font-size: map_get($dimensions, m),
          $font-weight: 600,
          $line-height: map_get($dimensions, l)
  );
  padding-top: map_get($dimensions, base);
  margin: 0;
  flex-basis: 40%;
}

.section__info {
  @include text-style(
    $color: map_get($colors, background-light-6),
    $font-family: $font-Raleway,
    $font-size: 1.125rem,
    $font-weight: 400,
    $line-height: map_get($dimensions, l)
  );
}

.decorative__image {
  position: absolute;
  z-index: 1;
}

@media only screen and(min-width: map_get($breakpoints, screen-md)) {
  .two-row-text-section {
    padding-top: map_get($dimensions, xxl) * 2;
  }

  .two-row-text-section--padding-bottom {
    padding-bottom: map_get($dimensions, xxl) * 2;
  }

  .section__text {
    margin-bottom: map_get($dimensions, xxl);
  }

  .section__title {
    font-size: map_get($dimensions, base);
    line-height: map_get($dimensions, l);
  }

  .section__description {
    padding-right: map_get($dimensions, xxl);
  }

  .section__img {

    .image__desktop {
      display: block;
    }

    .image__mobile {
      display: none;
    }
  } 
}

@media only screen and(min-width: map_get($breakpoints, screen-lg)) {
  .two-row-text-section {
    padding-top: map_get($dimensions, xxl) * 3;
  }

  .two-row-text-section--padding-bottom {
    padding-bottom: map_get($dimensions, xxl) * 3;
  }
  
  .section__text {
    display: flex;
    justify-content: flex-start;
    margin-bottom: map_get($dimensions, l) * 2;
    flex-wrap: wrap;
  }

  .section__description-container {
    flex-basis: 40%;
  }
  
  .section__description {
    font-size: map_get($dimensions, xxl);
    line-height: map_get($dimensions, m) * 2;
    padding-right: map_get($dimensions, l) * 2;
  }

  .section__info {
    flex-basis: 55%;
    margin-top: 24px;
  }

  .button {
    margin-top: 18px;
  }
}